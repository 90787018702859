<template>
  <section>
    <h2 class="uppercase pl-4 lg:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Rolltor/Rollgitter/Garagentor
    </h2>
    <article class="container mx-auto p-12 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Rolltor/Rollgitter/Garagentor
      </h2>
      <div class="pb-10 text-gray-600">
        <p>
          Die Zahl der Einbrüche steigt auch bei Garagen. Diese bieten oft einen
          direkten Zugang zum Wohnraum und werden einfach aus den
          Führungsschienen gehebelt. Unsere Rolltore entsprechen dem besten und
          schönsten Standart für Ihre Garage. Sie punkten nicht nur bei unserer
          Qualität sondern auch bei der Sicherheit und Optik. Die Entscheidung
          für oder gegen ein Garagentor richtet sich nach den individuellen
          Anforderungen und der Einbausituation. Wir zeigen Ihnen, worauf es bei
          der richtigen Wahl der Rolltore ankommt - Unsere Rolltore werden für
          Sie Maßgefertigt.
          <br /><br />
          Ein elektrischer Antrieb für Ihr Garagentor bietet Ihnen die
          Möglichkeit, Ihr Rolltor automatisch zu steuern. Vor allem bei einem
          großen Garagentor-Maß mit besonders großer Breite ist ein elektrischer
          Antrieb des Tors eine komfortable und kraftsparende Lösung – ganz
          einfach auch steuerbar über Ihr Smartphone oder per Funksteuerung.
          <br /><br />
        </p>
        <div class="flex flex-row">
          <div class="mx-1"><i class="fas fa-check"></i></div>
          <div>TÜV geprüfte Einbruchshemmung<br /></div>
        </div>
        <div class="flex flex-row">
          <div class="mx-1"><i class="fas fa-check"></i></div>
          <div>Komfort<br /></div>
        </div>
        <div class="flex flex-row">
          <div class="mx-1"><i class="fas fa-check"></i></div>
          <div>Optische Aufwertung<br /></div>
        </div>
        <div class="flex flex-row">
          <div class="mx-1"><i class="fas fa-check"></i></div>
          <div>Qualität<br /></div>
        </div>
        <div class="flex flex-row">
          <div class="mx-1"><i class="fas fa-check"></i></div>
          <div>Verschiedene Ausführungen und Farben erhältlich<br /></div>
        </div>
      </div>
      <contacticons></contacticons>
    </article>
  </section>
</template>
<script>
import contacticons from "../components/contact";
export default {
  name: "App",
  components: {
    contacticons,
  },
};
</script>
